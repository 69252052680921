.Message {
   margin-bottom: 10px;
}

.Message p {
   display: flex;
   padding: 16px 5%;
   margin: 0;
   background-color: rgba(95, 196, 136, 0.1);
   line-height: 24px;
   align-items: center;
}

.Message strong {
   margin-right: 20px;
   font-weight: normal;
   color: rgb(95, 196, 136);
}

.Message a {
   display: inline-block;
   height: 34px;
   padding: 5px 15px;
   margin: -5px 0;
   border: none;
   border-radius: 0;
   background-color: rgba(95, 196, 136, 0.2);
   outline: none;
   box-sizing: border-box;
   font-size: 13px;
   text-decoration: none;
   color: rgb(95, 196, 136);
   cursor: pointer;
}

.Message p.error {
   background-color: rgba(255, 0, 0, 0.05);
}

.Message p.error strong {
   color: rgba(255, 0, 0, 1);
}

.Message p.error a {
   background-color: rgba(255, 0, 0, 0.2);
   color: rgba(255, 0, 0, 1);
}

@media only screen and (max-width:616px) {
   .Message p {
      flex-direction: column;
   }

   .Message strong {
      margin-right: 0;
   }
   
   .Message a {
      margin: 10px 0 0;
   }
}