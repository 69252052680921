.Description {
   display: flex;
   flex-direction: row;
   gap: 0 60px;
   padding: 0 5%;
   line-height: 24px;
}

.Description h3 {
   margin-top: 40px;
   font-size: 20px;
   font-weight: normal;
   color: rgba(95, 196, 136, 1);
}

.Description ul {
   font-size: 14px;
   padding: 0 0 0 30px;
   margin: 10px 0;
}

.Description article {
   display: flex;
   flex-direction: column;
   flex: 1;
}

.Description aside {
   display: flex;
   flex-direction: column;
   flex: 1;
   max-width: 500px;
   min-width: 400px;
}

.Description aside img {
   width: 100%;
   margin: 30px 0;
}

.Description aside i {
   display: inline-block;
   width: 20px;
   height: 20px;
   margin-left: 10px;
   border: 2px solid rgba(255, 0, 0, 1);
   background-color: rgba(255, 255, 255, 1);
   border-radius: 50%;
   text-align: center;
   font-style: normal;
   font-weight: bold;
   font-size: 11px;
   line-height: 19px;
   vertical-align: top;
}

@media only screen and (max-width:980px) {
   .Description {
      flex-direction: column;
   }
   
   .Description aside {
      max-width: none;
      min-width: auto;
   }

   .Description aside img {
      width: 500px;
      align-self: center;
   }
}

@media only screen and (max-width:616px) {
   .Description aside img {
      width: 100%;
   }
}