@keyframes rotation {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.Loading {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-color: rgba(255, 255, 255, 0.7);
}

.Loading div {
   position: absolute;
   left: 50%;
   top: 50%;
   width: 160px;
   transform: translate(-50%, -50%);
   text-align: center;
}

.Loading span {
   content: '';
   display: inline-block;
   width: 50px;
   height: 50px;
   border: 3px solid rgba(220, 220, 220, 0.5);
   border-bottom-color: rgba(95, 196, 136, 0.8);
   border-radius: 50px;
   box-sizing: border-box;
   animation: 1s linear infinite rotation;
}

.Loading p {
   width: 100%;
   margin: 10px 0;
   font-family: Helvetica;
   font-size: 14px;
}