.Payment button {
   display: inline-block;
   height: 42px;
   padding: 10px 20px;
   margin: 30px 0;
   border: none;
   border-radius: 0;
   background-color: rgba(95, 196, 136, 0.2);
   outline: none;
   box-sizing: border-box;
   font-size: 13px;
   text-decoration: none;
   color: rgb(95, 196, 136);
   cursor: pointer;
}