.Chances {
   width: 100%;
   padding-top: 40px;
   font-size: 16px;
   line-height: 24px;
}

.Chances details,
.Chances small {
   display: block;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Chances details {
   padding: 10px 0;   
}

.Chances small {
   padding-bottom: 20px;
   color:rgba(0, 0, 0, 0.5);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.Chances strong,
.Chances b,
.Chances i {
   display: inline-block;
   font-style: normal;
   font-weight: normal;
   padding-right: 20px;
}

.Chances strong {
   position: relative;
   width: 45px;
   padding-left: 40px;
}

.Chances b,
.Chances i {
   width: 55px;
   text-align: right;
}

.Chances details small {
   padding: 30px 0 0;
   border: none;
}

.Chances details ol,
.Chances details small {
   padding-left: 50px;
}

.Chances details ol strong,
.Chances details small strong {
   width: 55px;
   padding-left: 0;
}

.Chances details ol b,
.Chances details small b {
   width: 90px;
   text-align: left;
}

.Chances details ol li.certain {
   color: rgba(0, 0, 0, 0.25);
}

.Chances details ol li p, 
.Chances details summary {
   display: inline-block;
   width: 100%;
   margin: 0;
   outline: none;
   cursor: pointer;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   vertical-align: top;
}

.Chances details summary::-webkit-details-marker {
   display: none;
} 

.Chances details summary:first-of-type {
   list-style-type: none;
}

.Chances details summary strong::before,
.Chances details summary strong em {
   position: absolute;
   left: 0;
   top: 8px;
   bottom: 8px;
   width: 30px;
   border: 1px solid rgba(0, 0, 0, 0.1);
   box-sizing: border-box;
}

.Chances details summary strong::before {
   content: '';
   border: 1px solid rgba(0, 0, 0, 0.1);
   background-color: rgba(0, 0, 0, 0.05);
   box-sizing: border-box;
}

.Chances details summary strong em {
   background-color: rgba(95, 196, 136, 0.5);
}

@media screen and (max-width: 600px) {
   .Chances details {
      font-size: 2.65vmin;
   }

   .Chances small {
      font-size: 2.2vmin;
   }

   .Chances strong {
      width: 8.3%;
      padding-right: 3.7%;
   }

   .Chances b,
   .Chances i {
      width: 10.2%;
      padding-right: 3.7%;
   }

   .Chances details ol,
   .Chances details small {
      padding-left: 9.26%;
   }

   .Chances details ol strong,
   .Chances details ol i,
   .Chances details small strong,
   .Chances details small i {
      width: 11.22%;
      padding-right: 4.1%;
   }

   .Chances details ol b,
   .Chances details small b {
      width: 18.37%;
      padding-right: 4.1%;
   }
}

@media screen and (max-width: 480px) {
   .Chances details {
      font-size: 12.8px;
   }

   .Chances small {
      font-size: 10.5px;
   }

   .Chances strong,
   .Chances b,
   .Chances i {
      padding-right: 16px;
   }

   .Chances strong {
      width: 36px;
   }
   
   .Chances b,
   .Chances i {
      width: 44px;
   }

   .Chances details ol,
   .Chances details small {
      padding-left: 40px;
   }

   .Chances details ol strong,
   .Chances details ol i,
   .Chances details small strong,
   .Chances details small i {
      width: 44px;
      padding-right: 16px;
   }

   .Chances details ol b,
   .Chances details small b {
      width: 72px;
      padding-right: 16px;
   }
}