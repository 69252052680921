.App {
   display: flex;
   flex-direction: column;
   min-height: 100%;
}

.App header {
   position: relative;
   padding: 10px 15px 10px 140px;
   background-color: black;
}

.App header img {
   position: absolute;
   left: 20px;
   top: 50%;
   width: 100px;
   background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(255, 255, 255, 1);
   border-radius: 50%;
   transform: translate(0, -50%);
}

.App header h1,
.App header h2 {
   margin: 10px 5px;
   color: rgba(255, 255, 255, 1);
}

.App header h1 {
   font-size: 24px;
   color: rgba(255, 255, 255, 1);
}

.App header h2 {
   font-size: 16px;
   font-weight: normal;
   color: rgba(255, 255, 255, 0.5);
}

.App header form input {
   position: relative;
   display: inline-block;
   width: 340px;
   height: 42px;
   padding: 11px 45px 11px 15px;
   margin: 5px;
   background: rgba(255, 255, 255, 0.1);
   border: 1px solid rgba(255, 255, 255, 0.2);
   border-radius: 0;
   box-sizing: border-box;
   font-size: 13px;
   appearance: none;  
   color: rgba(255, 255, 255, 1);
}

.App header form input:focus {
   border: 1px solid rgba(95, 196, 136, 0.8);
   outline: none;
}

.App header form button {
   flex: 0 0 auto;
   align-self: center;
   height: 42px;
   padding: 10px 20px;
   margin: 5px;
   border: none;
   border-radius: 0;
   background-color: rgba(255, 255, 255, 0.2);
   outline: none;
   box-sizing: border-box;
   font-size: 13px;
   color: rgba(255, 255, 255, 1);
   cursor: pointer;
}

.App header form button:hover {
   background-color: rgba(255, 255, 255, 0.15);
}

.App header form button:active {
   background-color: rgba(95, 196, 136, 0.8);
   color: rgba(255, 255, 255, 1);
}

.App main {
   display: flex;
   flex: 1;
   flex-direction: column;
   padding: 40px;
}

@media only screen and (max-width:980px) {
   .App header {
      padding: 0 15px 30px;
      margin-top: 80px;
      text-align: center;
   }

   .App header img {
      position: relative;
      left: auto;
      top: -70px;
      width: 125px;
      border-width: 2px;
      margin-bottom: -70px;
      transform: translate(0, 0);
   }

   .App header form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 10%;
   }

   .App header form .Select,
   .App header form input,
   .App header form button {
      flex: 1;
      width: auto;
      min-width: 80%;
   }
}

@media screen and (max-width: 800px) {
   .App main {
      padding: 40px 5%;
   }
}