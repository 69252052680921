.Select {
   display: inline-block;
   position: relative;
   width: 340px;
   margin: 5px;
   border: 1px solid rgba(255, 255, 255, 0.2);
   background-color: rgba(255, 255, 255, 0.1);
   box-sizing: border-box;
   vertical-align: top;
   color: rgba(255, 255, 255, 1);
}

.Select select {
   position: relative;
   display: block;
   width: 100%;
   height: 40px;
   padding: 10px 40px 10px 10px;
   background: transparent;
   border: 1px solid transparent;
   border-radius: 0;
   box-sizing: border-box;
   font-size: 13px;
   appearance: none;  
   color: rgba(255, 255, 255, 1);
}

.Select select:focus {
   border: 1px solid rgba(95, 196, 136, 0.8);
   outline: none;
}

.Select option {
   font-size: 14px;
   color: rgba(0, 0, 0, 1);
}

.Select option[disabled] {
   color: rgba(0, 0, 0, 0.5);
}

.Select span {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   width: 40px;
   background-color: rgba(0, 0, 0, 0.5);
   pointer-events: none;
}

.Select span > b {
   position: absolute;
   left: 50%;
   top: 50%;
   width: 40px;
   height: 40px;
   line-height: 46px;
   font-family: 'Courier New', Courier, monospace;
   font-weight: normal;
   text-align: center;
   transform: rotate(180deg) translate(50%, 50%);
}
