.Subscription {
   display: flex;
   flex-direction: row;
   gap: 0 60px;
   padding: 0 5%;
   line-height: 24px;
}

.Subscription h3 {
   margin-top: 40px;
   font-size: 20px;
   font-weight: normal;
   color: rgba(95, 196, 136, 1);
}

.Subscription strike {
   color: rgb(180, 180, 180);
}

.Subscription ul {
   font-size: 14px;
   padding: 0 0 0 30px;
   margin: 10px 0;
}

.Subscription article {
   display: flex;
   flex-direction: column;
   flex: 1;
}

.Subscription aside {
   position: relative;
   display: flex;
   flex-direction: column;
   flex: 1;
   max-width: 500px;
   min-width: 400px;
   padding-left: 50px;
}

.Subscription aside::before {
   content: '';
   position: absolute;
   left: 0;
   top: 40px;
   bottom: 0;
   width: 0;
   border-left: 1px dashed rgb(200, 200, 200);
}

.Subscription aside img {
   width: 100%;
   margin: 30px 0;
}

.Subscription aside dl {
   display: flex;
   padding: 20px 40px 20px 30px;
   margin: 20px 0 40px;
   border-radius: 8px;
   background-color: rgba(247, 158, 27, 0.1);
   align-items: center;
}

.Subscription aside dl dt {
   flex: 1;
   padding: 0;
   margin: 0 20px 0 0;
   font-size: 20px;
   font-weight: normal;
   color: rgb(247, 158, 27);
   align-items: center;
}

.Subscription aside dl dt p {
   margin: 0;
   font-size: 13px;
   color:rgb(180, 180, 180);
}

.Subscription aside dl dd {
   display: flex;
   padding: 0;
   margin: 0;
   font-size: 42px;
   font-weight: normal;
   line-height: 42px;
   gap: 10px;
}

.Subscription aside dl dd strike {
   font-size: 20px;
}

@media only screen and (max-width:980px) {
   .Subscription {
      flex-direction: column-reverse;
   }
   
   .Subscription aside {
      max-width: none;
      min-width: auto;
      padding-bottom: 20px;
      padding-left: 0;
   }

   .Subscription aside::before {
      left: 0;
      right: 0;
      top: auto;
      width: auto;
      height: 0;
      border-top: 1px dashed rgb(200, 200, 200);
      border-left: none;
   }

   .Subscription aside img {
      width: 500px;
      align-self: center;
   }
}

@media only screen and (max-width:616px) {
   .Subscription aside img {
      width: 100%;
   }
}